.box-show-address-text {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  > div {
    &:first-child {
      border: 1px solid #34384c;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 7px 15px;
      display: flex;
      align-items: center;
      height: 46px;
      flex: 1;

      p {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #fcfcfd;
        margin-bottom: 0;
        white-space: break-spaces;
        word-break: break-word;
      }

      @media (min-width: 991px) {
        max-width: 520px;
        margin-right: 16px;
        padding: 14px 29px;

        p {
          font-size: 18px;
        }
      }
    }

    &:last-child {
      position: relative;

      img {
        width: 54px;
        cursor: pointer;

        &:last-child {
          display: none;

          @media (min-width: 991px) {
            display: inline-block;
          }
        }
      }

      .tooltip {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
      }
    }
  }

  @media (min-width: 991px) {
    margin-bottom: 60px;
  }
}
