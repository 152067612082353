@import 'assets/css/variables.scss';

.warp-register-home {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  .register-home {
    width: 100%;
    //

    @include media-up(xs) {
      // padding: 0 10px;
      max-width: 300px;
    }

    @include media-up(xm) {
      // padding: 0 10px;
      max-width: 350px;
    }

    @include media-up(sm) {
      max-width: 500px;
    }

    @include media-up(md) {
      max-width: 700px;
    }

    @include media-up(lg) {
      max-width: 1000px;
    }
    position: relative;
    padding: 50px 15px;
    background: rgba(47, 50, 65, 0.5);
    backdrop-filter: blur(14px);
    border-radius: 12px;
    z-index: 1;
    text-align: center;

    .title-block {
      max-width: 100%;
      margin-bottom: 40px;

      p {
        font-family: 'Graphik';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #e5e6ed;
        margin-bottom: 8px;
      }

      h2 {
        font-family: 'Sofia Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 110%;
        margin-bottom: 0;
      }

      @media (min-width: 991px) {
        p {
          font-size: 20px;
          line-height: 27px;
        }
        h2 {
          font-size: 39px;
        }
      }
    }
  }
}
