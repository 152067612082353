.title-table-history {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 27px;
  color: #ffffff;
  display: none;

  @media (min-width: 991px) {
    display: block;
  }
}

.table-history {
  .ant-table {
    overflow: auto;
  }

  .ant-table-container {
    min-width: 360px;
  }

  .ant-table,
  .ant-table-thead > tr > th {
    background-color: transparent;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid #34384c;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: rgba($color: #ffffff, $alpha: 0.005);
  }

  .ant-table-thead > tr > th {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #5d6588;
  }

  .time,
  .action,
  .qty,
  .amount {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 0;
  }

  .time {
    color: #a5adcf;
  }

  .action {
    color: #ffffff;
  }

  .qty {
    text-align: center;
    color: #ffffff;
  }

  .amount {
    text-align: right;
    color: #ffffff;
  }

  @media (min-width: 991px) {
    .ant-table-thead > tr > th {
      font-size: 16px;
      line-height: 24px;
    }

    .time,
    .action,
    .qty,
    .amount {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
