@import 'assets/css/variables.scss';

.authen-code {
  max-width: 580px;
  .authen-title {
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    line-height: 44px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 10px;

    @include media-up(sm) {
      font-size: 32px;
    }
  }
  .authen-des {
    color: #e5e6ed;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 40px;
  }

  .authen-error {
    color: $warning;
    font-size: 13px;
    text-align: center;
    padding: 20px 10px;
    @include media-up(sm) {
      font-size: 16px;
    }
  }
  .authen-action {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    button {
      max-width: 288px;
    }
  }

  .authen-exclamation {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    padding: 20px;
    margin-top: 40px;

    background: #141518;
    border-radius: 10px;

    svg {
      margin-right: 10px;
    }
    p {
      color: #a5adcf;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
