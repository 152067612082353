.warp-account-contact {
  position: relative;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 25px;

  .account-contact {
    max-width: 1350px;
    padding: 15px;
    width: 100%;
    margin: auto;

    .background-contact {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      object-fit: cover;
      z-index: -1;
      animation: scaleBackground 20s infinite;
    }

    .item-contact-left {
      height: 100%;
      min-height: 100px;
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 15px;
      border-radius: 12px;
      position: relative;
      z-index: 2;
      overflow: hidden;

      p {
        &:first-child {
          font-family: 'Sofia Pro';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 160%;
          color: #ffffff;
        }

        &:last-child {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #ffffff;
        }
      }

      @media (min-width: 768px) {
        justify-content: flex-end;
      }

      @media (min-width: 991px) {
        padding-right: 80px;
        min-height: 170px;

        p {
          &:first-child {
            font-size: 32px;
          }

          &:last-child {
            font-size: 16px;
          }
        }
      }
    }

    .item-contact-right {
      height: 100%;
      min-height: 100px;
      background-size: cover;
      background-position: center;
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      border-radius: 15px;
      position: relative;
      z-index: 2;
      overflow: hidden;

      > div {
        &:first-child {
          margin-right: 24px;
        }

        &:last-child {
          p {
            font-family: 'Helvetica';
            font-style: normal;
            color: #ffffff;

            &:first-child {
              font-weight: 700;
              font-size: 14px;
              line-height: 140%;
            }

            &:nth-child(2) {
              font-weight: 400;
              font-size: 12px;
              line-height: 150%;
              margin-bottom: 7px;
            }
          }

          button {
            padding: 3px 20px;
            font-family: 'Graphik';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            color: #2d3748;
            background: #ffffff;
            border-radius: 10px;
            border: none;
            cursor: pointer;

            &:active {
              background: rgba($color: #ffffff, $alpha: 0.9);
            }
          }
        }
      }

      @media (min-width: 991px) {
        flex-direction: column;
        min-height: 170px;
        > div {
          &:first-child {
            margin-bottom: 20px;
          }

          &:last-child {
            button {
              padding: 10px 40px;
              font-size: 13px;
              line-height: 150%;

              &:active {
                background: rgba($color: #ffffff, $alpha: 0.9);
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 991px) {
    padding-top: 40px;
    padding-bottom: 35px;
  }
}
