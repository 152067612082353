.package-item-info {
  // border: 1px solid rgba(255, 255, 255, 0.12);
  background: transparent;
  box-shadow: unset;
  // border-radius: 12px;
  max-width: 285px;
  margin: auto;

  // padding: 22px 24px;

  margin-bottom: 18px;

  cursor: pointer;
  .wrapper-item {
    border-radius: 12px;
    padding: 22px 24px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    // border-radius: 12px;
    &:hover {
      background: rgba(255, 255, 255, 0.08);
      box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);

      .footer-item {
        .btn-primary {
          background: #06c694;
        }
      }
    }
  }
  // &:hover {
  //   background: rgba(255, 255, 255, 0.08);
  //   box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);

  //   .footer-item {
  //     .btn-primary {
  //       background: #06c694;
  //     }
  //   }
  // }

  .header-item {
    margin-bottom: 32px;

    p {
      &:first-child {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #ffffff;
        margin-bottom: 16px;
      }

      &:last-child {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        text-align: center;
        color: rgba(255, 255, 255, 0.48);
        margin-bottom: 0;
      }
    }
  }

  .body-item {
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    margin-bottom: 32px;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        // white-space: nowrap !important;
        img {
          margin-right: 13px;
          width: 16.67px;
          max-width: 100%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer-item {
    .package-price {
      p {
        margin-bottom: 0;

        &.price {
          display: inline-block;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 25px;
          line-height: 1;
          color: #ffffff;
          position: relative;
          float: left;

          &::after {
            content: '';
            width: 110%;
            height: 2px;
            background: #06c694;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &.price-sale {
          display: inline-block;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 1;
          background: linear-gradient(90deg, #52ffc0 0%, #00c291 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          float: right;

          span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 180%;
            background: #ffffff;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
      }
    }

    .btn-primary {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 14px 32px;
      height: 49px;
      border-radius: 50px;
      border: none;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      background: rgba(255, 255, 255, 0.08);
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      margin: auto;
      margin-top: 32px;
      cursor: pointer;
      transition: 0.15s;
    }
  }

  &:nth-child(even) {
    background: transparent;
    box-shadow: unset;
  }

  @media (min-width: 991px) {
    padding: 64px 24px;
    max-width: 355px;

    .header-item {
      min-height: 130px;

      p {
        &:first-child {
          font-size: 24px;
        }

        &:last-child {
          font-size: 16px;
        }
      }
    }
    .body-item {
      min-height: 280px;
      padding: 15px;

      ul {
        li {
          font-size: 14px;

          img {
            margin-right: 12px;
            width: 20px;
          }
        }
      }
    }

    .footer-item {
      .package-price {
        max-width: 220px;
        margin: auto;
      }

      .btn-primary {
        position: static;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
