.referral-gift {
  p {
    &:first-child {
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 130%;
      color: #2dbc8e;
      margin-bottom: 8px;
    }

    &:last-child {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #a5adcf;
      margin-bottom: 0;
    }
  }

  @media (min-width: 991px) {
    p {
      &:first-child {
        font-size: 25px;
        line-height: 120%;
      }

      &:last-child {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
