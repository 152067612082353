.trading-home {
  padding-bottom: 40px;
  // height: 100vh;

  .content {
    width: 100%;
    max-width: 835px;
    margin: auto;
    position: relative;
    z-index: 1;

    .box-background {
      position: relative;

      .bg-all {
        width: 100%;
        margin-bottom: 32px;
        cursor: pointer;
      }

      .list-bg-image {
        width: 100%;
        height: 100%;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          pointer-events: none;

          &:nth-child(1) {
            transform: translate(18%, -50%);
            width: 41%;
            --translateX: 18%;
            --translateY: -50%;
            animation: UpAndDown 8s infinite;
          }

          &:nth-child(2) {
            transform: translate(241%, -189%);
            width: 15%;
            --translateX: 241%;
            --translateY: -189%;
            animation: UpAndDown 8s infinite;
            animation-delay: 4s;
          }

          &:nth-child(3) {
            transform: translate(-1259%, -289%);
            width: 4%;
            --translateX: -1259%;
            --translateY: -289%;
            animation: UpAndDown 4s infinite;
          }

          &:nth-child(4) {
            transform: translate(-722%, -535%);
            width: 6%;
            --translateX: -722%;
            --translateY: -535%;
            animation: UpAndDown 2s infinite;
          }

          &:nth-child(5) {
            transform: translate(1377%, 265%);
            width: 3.5%;
            --translateX: 1377%;
            --translateY: 265%;
            animation: UpAndDown 3s infinite;
          }
        }
      }
    }

    .btn-primary {
      width: 196px;
      height: 47px;
      font-family: 'Sofia Pro';
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      margin: auto;
    }
  }

  @media (min-width: 991px) {
    height: 100vh;
    .content {
      .btn-primary {
        width: 287px;
        height: 65px;
        font-size: 16px;
      }
    }
  }
}

.btn-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 32px;
  background: #06c694;
  border-radius: 50px;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;

  &:active {
    opacity: 0.9;
  }
}

//   --translateX: -470%;
//   --translateY: -238%;
//   animation: UpAndDown 2s infinite;
@keyframes UpAndDown {
  0% {
    transform: translate(var(--translateX), var(--translateY));
  }
  50% {
    transform: translate(var(--translateX), calc(var(--translateY) - 10px));
  }
  100% {
    transform: translate(var(--translateX), var(--translateY));
  }
}
