@import 'assets/css/variables.scss';

.deposit-banner {
  width: 100%;
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  overflow: hidden;

  .background-1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: scaleBackground 20s infinite;
  }

  .deposit-body {
    position: absolute;
  }

  h2 {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    margin-bottom: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    li {
      position: relative;
      padding: 0 17px 0 12px;
      line-height: 12px;

      &::after {
        content: '/';
        color: #8a8aa0;
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        position: absolute;
        left: auto;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      a {
        color: #8a8aa0;
        font-family: 'Sofia Pro';
        font-weight: 400;
        font-size: 12px;
        line-height: 28px;
        text-transform: capitalize;

        &:hover {
          color: #ffffff;
        }
        &:active {
          color: #ffffff;
        }
      }

      &:last-child {
        padding: 0 12px;

        a {
          color: #ffffff;
        }

        &::after {
          content: unset;
        }
      }
    }
  }

  @media (min-width: 991px) {
    height: 240px;

    h2 {
      font-size: 48px;
      line-height: 53px;
    }

    ul {
      margin-top: 17px;
      li {
        line-height: 28px;

        &::after {
          font-size: 18px;
          line-height: 28px;
        }

        a {
          font-size: 18px;
        }
      }
    }
  }
}
