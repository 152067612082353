@import 'assets/css/variables.scss';

.input-code-container {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  input {
    color: #ffffff;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;

    flex: 1 1 auto;
    width: 100%;
    height: 71px;
    text-align: center;
    background: rgba(47, 50, 65, 0.5);
    border: 1px solid #34384c;
    border-radius: 5px;

    &:valid {
      outline: unset;
    }
    &:focus,
    &:hover {
      border: 1px solid #414768;
      outline: unset;
    }

    &:not(:first-child) {
      margin-left: 8px;
      @include media-up(sm) {
        margin-left: 20px;
      }
    }

    &.error {
      border: 1px solid $warning;
    }
  }
}
