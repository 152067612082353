.warp-account-control {
  position: relative;
  margin-top: -40px;
  width: 100%;
  padding: 0 15px;
  padding-bottom: 20px;

  .account-control {
    max-width: 1350px;
    width: 100%;
    margin: auto;

    .item-control {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 100px;
      width: 100%;
      padding: 24px 20px;
      background: #1e1f25;
      box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
      border-radius: 15px;
      height: 100%;

      &.active {
        background: #019c75;
      }

      > div {
        &:first-child {
          p {
            &:first-child {
              font-family: 'Graphik';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              color: #ffffff;
            }

            &:last-child {
              font-family: 'Graphik';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 140%;
              color: #ffffff;
            }
          }
        }

        &:last-child {
          button {
            display: flex;
            align-items: center;
            background: #ffffff;
            border-radius: 100px;
            padding: 12px 24px;
            font-family: 'Sofia Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #2dbc8e;
            border: none;
            cursor: pointer;

            img {
              margin-right: 10px;
            }

            &:active {
              background: rgba($color: #ffffff, $alpha: 0.9);
            }
          }
        }
      }
    }
  }

  @media (min-width: 991px) {
    padding-bottom: 25px;

    .account-control {
      .item-control {
        padding: 24px 18px;

        > div {
          &:first-child {
            p {
              &:first-child {
                font-size: 16px;
              }

              &:last-child {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}
