.warp-page-billing {
  background-color: #141518;

  .warp-billing-content {
    width: 100%;
    padding: 24px;

    .billing-content {
      background: #1e1f25;
      border-radius: 12px;
      padding: 20px;
      max-width: 900px;
      margin: auto;

      .go-back {
        margin-bottom: 30px;

        @media (min-width: 991px) {
          margin-bottom: 40px;
        }
      }

      .box-start-exchange {
        padding: 24px 26px;
        background-size: 100% 100%;
        margin-bottom: 30px;

        .start-exchange {
          margin-bottom: 26px;

          p {
            &:first-child {
              font-family: 'Sofia Pro';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 130%;
              color: #ffffff;
              margin-bottom: 30px;
            }

            &:nth-child(2) {
              display: none;
              font-family: 'Graphik';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              margin-bottom: 16px;
            }
          }

          button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            background: #ffffff;
            border-radius: 100px;
            font-family: 'Sofia Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 22px;
            color: #00c291;
            border: none;
            min-width: 123px;
          }

          @media (min-width: 991px) {
            margin-bottom: 0;

            p {
              &:first-child {
                font-size: 32px;
                line-height: 160%;
                margin-bottom: 0;
              }
              &:nth-child(2) {
                display: block;
              }
            }
          }
        }

        .acount-balance {
          .item-balance {
            display: flex;
            align-items: center;

            > div {
              &:first-child {
                padding: 7.5px 0;
              }
            }

            img {
              margin-right: 24px;
            }

            p {
              margin-bottom: 0;
              color: #ffffff;
              font-family: 'Graphik';
              font-style: normal;

              &:first-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
              }

              &:last-child {
                font-weight: 700;
                font-size: 20px;
                line-height: 140%;
              }
            }
          }
        }

        @media (min-width: 991px) {
          margin-bottom: 50px;
        }
      }

      .referral-gift {
        margin-bottom: 40px;

        @media (min-width: 991px) {
          margin-bottom: 55px;
        }
      }

      @media (min-width: 991px) {
        padding: 55px 90px;
      }
    }
  }
}
