@import 'assets/css/variables.scss';

.notification-container {
  .notification-content {
    padding: 0 14px;
  }
  .notification-body {
    width: 100%;
    max-width: 900px;
    padding: 20px 16px 40px;
    margin: 10px auto 0;

    background: #1e1f25;
    border-radius: 12px;

    @include media-up(sm) {
      padding: 50px 70px 100px;
      margin: 50px auto 0;
    }

    .notification-title {
      color: #2dbc8e;
      font-family: 'Sofia Pro';
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      margin-top: 22px;
      @include media-up(sm) {
        font-size: 25px;
        line-height: 120%;
        margin-top: 40px;
      }
    }
    .notification-des {
      max-width: 600px;
      margin-top: 10px;
      margin-bottom: 20px;
      @include media-up(sm) {
        margin-bottom: 50px;
      }
    }

    .form-notification {
      padding-bottom: 20px;
      &:last-child {
        padding-top: 40px;
        border-top: 1px solid #34384c;
      }
      .ant-form-item {
        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          display: none;
        }
        label {
          width: 100%;
          display: flex;
          justify-content: space-between;

          span {
            color: #ffffff;
            font-weight: 600;
            font-size: 13px;
            line-height: 27px;
            @include media-up(sm) {
              font-size: 18px;
            }
          }
          &.ant-form-item-required span {
            position: relative;
            &:before {
              content: '*';
              color: #ff4d4f;
              display: inline-block;
              font-size: 14px;
              line-height: 1;
              position: absolute;
              right: -10px;
            }
          }

          button {
            font-weight: 500;
            font-size: 12px;
            line-height: 21px;
            text-align: center;

            max-width: 80px;
            height: 30px;
            padding: 0 20px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @include media-up(sm) {
              font-size: 14px;
              height: 40px;
              max-width: 110px;
            }

            &:hover {
              opacity: 0.7;
            }
            &:active {
              opacity: 1;
            }
          }

          .btn-active {
            color: #ffffff;
            background: transparent;
            border: 1px solid #a5adcf;
            border-radius: 50px;
            cursor: pointer;
          }
          .btn-disable {
            color: #fa2256;
            background: transparent;
            border: 1px solid #fa2256;
            border-radius: 50px;
            cursor: pointer;
          }
        }

        .ant-form-item-control-input-content {
          max-width: 370px;
          position: relative;
          input {
            color: #fcfcfd;
            font-weight: 700;
            font-size: 13px;
            line-height: 16px;

            width: 100%;
            max-width: 370px;
            height: 43px;
            padding: 10px 30px 10px 16px;
            background: transparent;
            border: 1px solid #34384c;
            border-radius: 5px;
            &:valid,
            &:hover {
              border: 1px solid #34384c;
              outline: unset;
            }
            @include media-up(sm) {
              font-size: 18px;
            }
          }

          svg {
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
            fill: #14d1a4;
          }
        }

        .ant-form-item-explain-error {
          font-size: 12px;
          @include media-up(sm) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
