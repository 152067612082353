.warp-blog {
  background: #141518;

  .container {
    max-width: 1320px;
    width: 100%;
    margin: auto;
    padding: 0 15px;
  }
}
