@import 'assets/css/variables.scss';

.box-copy-link {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  .link {
    border: 1px solid #34384c;
    overflow: hidden;
    padding-right: 25px;
    flex: 1;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 16px;

    > div {
      height: 43px;
      display: flex;
      align-items: center;
      padding: 14px 15px;
      overflow: hidden;

      p {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #fcfcfd;
        white-space: nowrap;
      }
    }
  }

  .icon-copy {
    position: relative;
    img {
      height: 52px;
      cursor: pointer;
    }

    .tooltip {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
    }
  }

  @media (min-width: 991px) {
    .link {
      > div {
        p {
          font-size: 18px;
        }
      }
    }
  }
}
