.warp-account-history-login {
  position: relative;
  width: 100%;
  padding: 0 15px;
  padding-top: 25px;
  padding-bottom: 0;

  .account-history-login {
    max-width: 1350px;
    padding: 15px;
    width: 100%;
    margin: auto;

    > p {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 27px;
      color: #ffffff;
      margin-bottom: 32px;
    }

    .ant-table table p {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #ffffff;
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
      text-align: right;
    }

    .table-history .ant-table-tbody > tr > td:last-child {
      text-align: right;
    }
  }

  @media (min-width: 991px) {
    padding-top: 30px;
    padding-bottom: 0;

    .account-history-login {
      .ant-table table p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
