.user-home {
  margin-top: 50px;
  padding-bottom: 35px;
  // height: 100vh;

  .title-block {
    padding: 0 15px;
  }

  .content {
    width: 100%;
    padding: 0 15px;
    max-width: 822px;
    margin: auto;
    margin-top: 40px;

    .user-home-item {
      display: flex;
      max-width: 350px;
      margin: auto;

      .header-item {
        margin-right: 16px;

        img {
          max-width: 100%;
          width: 72px;
        }
      }

      .body-item {
        flex: 1;

        h5 {
          font-family: 'Sofia Pro';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 120%;
          color: #ffffff;
        }

        p {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #a5adcf;
          margin-bottom: 0;
        }
      }
    }
  }

  @media (min-width: 991px) {
    .content {
      .user-home-item {
        flex-direction: column;
        text-align: center;
        max-width: 100%;

        .header-item {
          margin-right: 0;
          margin-bottom: 32px;

          img {
            max-width: 100%;
            width: 111px;
          }
        }

        .body-item {
          h5 {
            font-size: 25px;
            margin-bottom: 8px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media (min-width: 991px) {
    padding-bottom: 80px;
  }
}
