@import 'assets/css/variables.scss';
@import 'assets/css/font-face.scss';
@import 'assets/css/animation.scss';

* {
  font-family: Graphik;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}
*::after,
*::before {
  box-sizing: border-box;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-thumb {
  background: #2dbc8e;
  border-radius: 3px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #5e6588;
  border-radius: 10px;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  color: #ffffff;
}

p {
  color: #a5adcf;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 0;
  @include media-up(sm) {
    font-size: 16px;
    line-height: 24px;
  }
}

#root {
  width: 100%;
  min-height: 100%;
  background-color: $secondary;
}
