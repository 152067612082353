.warp-account-feature {
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;

  .account-feature {
    max-width: 1350px;
    width: 100%;
    padding: 0 16.5px;
    margin: auto;

    > p {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #ffffff;
      margin-bottom: 16px;
    }

    .item-feature {
      text-align: center;
      background: rgba(47, 50, 65, 0.5);
      backdrop-filter: blur(4px);
      border-radius: 12px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 10px;

      > div {
        img {
          width: 45px;
          margin-bottom: 23px;
        }

        p {
          &.item-title {
            font-family: 'Sofia Pro';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            color: #ffffff;
            margin-bottom: 8px;
          }

          &.item-description {
            font-family: 'Graphik';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 150%;
            text-align: center;
            color: #a5adcf;
            margin-bottom: 8px;
          }
        }
      }

      > p {
        font-family: 'Sofia Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        color: #14d19d;
        cursor: pointer;
      }
    }
  }

  @media (min-width: 991px) {
    padding-top: 40px;
    padding-bottom: 30px;

    .account-feature {
      > p {
        font-size: 25px;
        margin-bottom: 43px;
      }

      .item-feature {
        padding: 43px 30px;

        > div {
          img {
            width: 85px;
            margin-bottom: 43px;
          }

          p {
            &.item-title {
              font-size: 25px;
            }

            &.item-description {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
