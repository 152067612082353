.warp-page-security {
  background-color: #141518;

  .warp-security-content {
    width: 100%;
    padding: 24px;

    .security-content {
      background: #1e1f25;
      border-radius: 12px;
      padding: 20px;
      max-width: 900px;
      margin: auto;

      .go-back {
        margin-bottom: 30px;

        @media (min-width: 991px) {
          margin-bottom: 40px;
        }
      }

      .box-risk-level {
        background: #141518;
        border-radius: 10px;
        padding: 17px 15px;
        margin-bottom: 64px;

        img {
          &.icon-security-checked {
            width: 23px;
            margin-right: 16px;
          }
        }
      }

      .list-item-security {
        .item-security {
          display: flex;
          justify-content: space-between;
          padding-top: 16px;
          padding-bottom: 16px;
          border-bottom: 1px solid #34384c;

          &:last-child {
            padding-bottom: 0;
            border-bottom: none;
          }

          &:first-child {
            padding-top: 0;
          }

          > div {
            &:first-child {
              p {
                font-family: 'Graphik';
                font-style: normal;
                color: #a5adcf;

                &:first-child {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 130%;
                  color: #ffffff;
                  margin-bottom: 8px;
                }

                &:last-child {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 150%;
                  margin-bottom: 0;
                }
              }
            }

            &:last-child {
              button {
                border: 1px solid #a5adcf;
                box-sizing: border-box;
                border-radius: 50px;
                padding: 7.5px 17px;
                background-color: transparent;
                cursor: pointer;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                text-align: center;
                color: #ffffff;

                &:active {
                  background-color: rgba($color: #ffffff, $alpha: 0.05);
                }

                &.disable {
                  color: #fa2256;
                  border: 1px solid #fa2256;
                }
              }
            }
          }
        }

        @media (min-width: 991px) {
          .item-security {
            align-items: center;
            padding-top: 32px;
            padding-bottom: 32px;

            > div {
              &:first-child {
                p {
                  &:first-child {
                    font-size: 18px;
                    line-height: 27px;
                  }

                  &:last-child {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
              }

              &:last-child {
                button {
                  font-size: 14px;
                  line-height: 21px;
                  padding: 9px 32px;
                }
              }
            }
          }
        }
      }

      @media (min-width: 991px) {
        padding: 66px 90px 185px 90px;

        .box-risk-level {
          padding: 27px 32px;
          margin-right: 16px;

          img {
            &.icon-security-checked {
              width: 38px;
            }
          }
        }
      }
    }
  }
}
