.box-referral-badge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 11px;

  .item-referral-badge {
    padding: 5px;
    border-radius: 10px;
    width: 187px;
    height: 88px;
    border: 1px solid transparent;
    cursor: pointer;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;

      p {
        &:first-child {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          margin-bottom: 2px;
        }

        &:last-child {
          font-family: 'Sora';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 23px;
          margin-bottom: 0;
        }
      }
    }

    &:first-child {
      &.active {
        border: 1px solid #52ffc0;
      }

      > div {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
          linear-gradient(90deg, #52ffc0 0%, #00c291 100%);

        p {
          &:first-child {
            color: #ffffff;
          }

          &:last-child {
            color: #ffffff;
          }
        }
      }
    }

    &:nth-child(2) {
      &.active {
        border: 1px solid #ffffff;
      }

      > div {
        background: #ffffff;

        p {
          &:first-child {
            color: #1e1f25;
          }

          &:last-child {
            color: #000000;
          }
        }
      }
    }

    &:nth-child(3) {
      &.active {
        border: 1px solid #000000;
      }

      > div {
        background: #000000;

        p {
          &:first-child {
            color: #a5adcf;
          }

          &:last-child {
            color: #ffffff;
          }
        }
      }
    }
  }

  @media (min-width: 991px) {
    .item-referral-badge {
      padding: 7px;
      width: 237px;
      height: 109px;

      > div {
        p {
          &:first-child {
            font-size: 16px;
            line-height: 24px;
          }

          &:last-child {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
}
