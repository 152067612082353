.blog-item {
  margin-bottom: 56px;

  &:last-child {
    margin-bottom: 0;
  }
  .content-text {
    h4 {
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-bottom: 16px;
    }

    p {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #a5adcf;
      opacity: 0.6;
      margin-bottom: 32px;
    }

    a {
      display: inline-block;
      padding: 14px 32px;
      background: #06c694;
      border-radius: 50px;
      border: none;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .content-image {
    img {
      width: 100%;
    }
  }

  .ant-row {
    .ant-col {
      .content-text {
        padding-left: 0;
        padding-right: 0;
      }

      &:first-child {
        order: 2;
      }

      &:last-child {
        order: 1;
      }
    }
  }

  @media (min-width: 991px) {
    .content-text {
      h4 {
        font-size: 32px;
      }

      p {
        font-size: 16px;
      }

      a {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .ant-row {
      .ant-col {
        .content-text {
          padding-right: 165px;
          padding-left: 66px;
        }

        &:first-child {
          order: 1;
        }

        &:last-child {
          order: 2;
        }
      }
    }

    &:nth-child(even) {
      .ant-row {
        .ant-col {
          .content-text {
            padding-left: 85px;
          }

          &:first-child {
            order: 2;
          }

          &:last-child {
            order: 1;
          }
        }
      }
    }
  }

  @media (min-width: 991px) {
    margin-bottom: 80px;
  }
}
