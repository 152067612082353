.warp-feature-home {
  position: relative;
  // padding-top: 60px;
  padding-bottom: 25px;
  min-height: 100vh;
  .feature-home {
    max-width: 1050px;
    margin: auto;
    padding: 0 15px;

    .background-home-2 {
      position: absolute;
      top: -50%;
      right: -20%;
      z-index: 0;
      animation: scaleBackground 20s infinite;
      transform-origin: 100% 50%;
    }

    .title-block {
      // margin-bottom: 44px;
    }

    .content {
      position: relative;
      z-index: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .item-feature {
        width: 100%;
        max-width: 100%;

        .item-feature-content {
          background: rgba(47, 50, 65, 0.5);
          backdrop-filter: blur(4px);
          border-radius: 12px;
          height: 300px;
          padding: 40px 28px 0 28px;
          text-align: center;
          margin: 16px;
          cursor: pointer;
        }

        img {
          width: 100%;
          max-width: 70px;
          margin-bottom: 33px;
        }
        h4 {
          font-family: 'Sofia Pro';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 120%;
          text-align: center;
          color: #ffffff;
          margin-bottom: 16px;
        }
        p {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: #a5adcf;
          margin-bottom: 0;
        }

        &:last-child {
          .item-feature-content {
            margin-bottom: 0;
          }
        }
      }

      @media (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;

        .item-feature {
          max-width: 50%;

          img {
            max-width: 91px;
          }

          &:last-child,
          &:nth-child(3) {
            .item-feature-content {
              margin-bottom: 0;
            }
          }
        }
      }

      @media (min-width: 991px) {
        flex-direction: row;
        flex-wrap: wrap;

        .item-feature {
          max-width: 33.33333%;

          .item-feature-content {
            height: 340px;
            width: 285px;
            max-width: 100%;

            &:hover {
              --translateX: 0%;
              --translateY: 0%;
              animation: UpAndDown 4s infinite;
            }
          }

          h4 {
            font-size: 25px;
          }

          p {
            font-size: 16px;
          }

          &:first-child,
          &:nth-child(3) {
            transform: translateY(50%);
          }

          &:last-child {
            .item-feature-content {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  @media (min-width: 991px) {
    padding-top: 30px;
    padding-bottom: 70px;
  }
}

.box-swap {
  position: relative;
  padding: 25px 15px 0 15px;
  min-height: 100vh;
  .title-block {
    margin-bottom: 40px;
  }

  .box-swap-content {
    width: 100%;
    // max-width: 320px;
    max-width: 640px;
    margin: auto;
    position: relative;
    z-index: 1;

    .box-image-background {
      .box-earth {
        width: 636px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        background-size: 636px;
        background-position: center;
        animation: spin 50s infinite linear;
        border: 5px solid #141518;
      }

      img {
        &:not(.background-earth) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:not(.background-earth):not(.background-circle-1):not(.background-circle-2) {
          cursor: pointer;

          &:hover {
            animation: none;
          }
        }

        &.background-earth {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }

        &.background-circle-1 {
          transform: translate(-55%, -70%) rotate(0deg);
          animation: rotate_circle_1 20s infinite;
        }

        &.background-circle-2 {
          transform: translate(-60%, -48%);
          animation: rotate_circle_2 10s infinite;
        }

        &.background-rocket {
          transform: translate(-470%, -238%);
          z-index: 2;
          --translateX: -470%;
          --translateY: -238%;
          animation: UpAndDown 2s infinite;
        }

        &.background-bitcoin {
          transform: translate(120%, -320%);
          z-index: 2;
          --translateX: 120%;
          --translateY: -320%;
          animation: UpAndDown 5s infinite;
        }

        &.background-bnb {
          transform: translate(-88%, -469%);
          --translateX: -88%;
          --translateY: -469%;
          animation: UpAndDown 3s infinite;
        }

        &.background-ethereum {
          transform: translate(-264%, 80%);
          z-index: 2;
          --translateX: -264%;
          --translateY: 80%;
          animation: UpAndDown 1s infinite;
        }

        &.background-usdt {
          transform: translate(-182%, 315%);
          --translateX: -182%;
          --translateY: 315%;
          animation: UpAndDown 4s infinite;
        }
      }
    }

    .specifications-box {
      .specifications-item {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #1e1f25;
        border-radius: 12px;
        padding: 24px;
        display: flex;
        cursor: pointer;
        visibility: hidden;

        > div {
          &:last-child {
            margin-left: 18px;
          }
        }

        p {
          font-family: 'Graphik';
          font-style: normal;
          line-height: 27px;

          &:first-child {
            font-weight: 600;
            font-size: 25px;
            color: #11cabe;
            margin-bottom: 8px;
          }

          &:last-child {
            font-weight: 400;
            font-size: 18px;
            color: #a5adcf;
            margin-bottom: 0;
          }
        }

        &:first-child {
          width: 260px;
          z-index: 1;
          transform: translate(-112%, -199%);
          &:hover {
            --translateX: -112%;
            --translateY: -199%;
            animation: UpAndDown 2s infinite;
          }
        }

        &:nth-child(2) {
          width: 210px;
          z-index: 1;
          transform: translate(75%, -216%);
          &:hover {
            --translateX: 75%;
            --translateY: -216%;
            animation: UpAndDown 2s infinite;
          }
        }

        &:nth-child(3) {
          width: 230px;
          z-index: 1;
          transform: translate(-128%, 23%);
          &:hover {
            --translateX: -128%;
            --translateY: 23%;
            animation: UpAndDown 2s infinite;
          }
        }

        @media (min-width: 991px) {
          visibility: visible;
        }
      }
    }
  }

  .background-home-3 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    animation: scaleBackground 20s infinite;
    transform-origin: 100% 50%;
  }

  @media (min-width: 991px) {
    padding: 70px 15px;
  }
}

.title-block {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 630px;
  margin: auto;

  h2 {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;
    text-align: center;
    color: #ffffff;
    margin-bottom: 24px;
  }

  p {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #e5e6ed;
    margin-bottom: 0;
  }

  @media (min-width: 991px) {
    h2 {
      font-size: 49px;
    }

    p {
      font-size: 16px;
    }
  }
}

.box-buy-token {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 406px;
  text-align: center;
  background: rgba(47, 50, 65, 0.5);
  backdrop-filter: blur(64px);
  border-radius: 20px;
  padding: 35px 32px 28px 32px;
  max-width: 100%;

  p {
    font-family: 'Graphik';
    font-style: normal;
    color: #ffffff;

    &:nth-child(1) {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    &:nth-child(2) {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 70px;

      span {
        color: #a5adcf;
      }
    }
  }

  .ant-input-group {
    display: flex;
    margin-bottom: 60px;

    .ant-select {
      width: 50%;

      .ant-select-selection-item {
        display: flex;
        align-items: center;

        img {
          margin-right: 8px;
        }
      }

      .ant-select-selector {
        height: 60px;
        background: rgba(47, 50, 65, 0.5);
        border: 1px solid #34384c;
        border-left: 1px solid rgba(47, 50, 65, 0.5);
        box-sizing: border-box;
        font-family: 'Sofia Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 160%;
        color: #ffffff;
        border-top-right-radius: 100px !important;
        border-bottom-right-radius: 100px !important;
      }

      .ant-select-arrow {
        color: #ffffff;
        right: 32px;
      }
    }

    .ant-input {
      flex: 1;
      text-align: left;
      padding-left: 32px;
      height: 60px;
      background: rgba(47, 50, 65, 0.5);
      border: 1px solid #34384c;
      border-right: 1px solid rgba(47, 50, 65, 0.5);
      box-sizing: border-box;
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }
  }

  @media (min-width: 991px) {
    transform: translate(0%, -17%);

    .ant-input-group {
      .ant-select {
        width: 154px;
      }
    }
  }
}

@keyframes rotate_circle_1 {
  0% {
    transform: translate(-55%, -70%) rotate(0deg) scale(1);
  }
  50% {
    transform: translate(-55%, -70%) rotate(360deg) scale(1.1);
  }
  100% {
    transform: translate(-55%, -70%) rotate(0deg) scale(1);
  }
}

@keyframes rotate_circle_2 {
  0% {
    transform: translate(-60%, -48%) rotate(0deg) scale(1);
  }
  50% {
    transform: translate(-60%, -48%) rotate(360deg) scale(1.1);
  }
  100% {
    transform: translate(-60%, -48%) rotate(0deg) scale(1);
  }
}

@keyframes UpAndDown {
  0% {
    transform: translate(var(--translateX), var(--translateY));
  }
  50% {
    transform: translate(var(--translateX), calc(var(--translateY) - 5px));
  }
  100% {
    transform: translate(var(--translateX), var(--translateY));
  }
}

@keyframes spin {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 636px;
  }
}
