.container-blog {
  position: relative;
  height: 450px;
  max-height: 100vh;
  min-height: 450px;
  width: 100%;
  background: #141518;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 24px;

  .warp-banner {
    .box-image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 34px;

      img {
        width: 55px;
        max-width: 100%;
        height: auto;
        display: block;
        position: relative;

        &:first-child {
          z-index: 1;
          transform: translateY(3px);
          animation: upAndDownTranslate 3s infinite;
        }

        &:last-child {
          z-index: 0;
          margin-left: -15px;
          transform: translateY(3px);
          animation: upAndDownTranslate 3s infinite;
          animation-delay: 1.5s;
        }
      }
    }

    h1 {
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 110%;
      text-align: center;
      background: -webkit-linear-gradient(90deg, #52ffc0 0%, #00c291 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      max-width: 765px;
      width: 100%;
      margin: auto;
      margin-bottom: 20px;
    }

    p {
      font-family: 'FS Axiforma';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #6b7086;
      opacity: 0.6;
      margin-bottom: 0;
    }

    .list-dot {
      img {
        &.banner-dot {
          pointer-events: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transition: 1s;

          &:nth-child(1) {
            width: 18px;
            transform: translate(-1500%, -1500%);
            animation: upAndDownMargin 2s infinite;
          }

          &:nth-child(2) {
            width: 12px;
            transform: translate(-4500%, 2000%);
            animation: upAndDownMargin 3s infinite;
          }

          &:nth-child(3) {
            left: auto;
            right: 50%;
            width: 20px;
            transform: translate(3000%, 1000%);
            animation: upAndDownMargin 4s infinite;
          }

          &:nth-child(4) {
            width: 12px;
            transform: translate(-5500%, -500%);
            animation: upAndDownMargin 5s infinite;
          }

          &:nth-child(5) {
            left: auto;
            right: 50%;
            width: 12px;
            transform: translate(3500%, -1000%);
            animation: upAndDownMargin 6s infinite;
          }
        }
      }

      .banner-rocket {
        position: absolute;
        top: 20%;
        left: 0;
        animation: rocketFly 30s infinite;
        transform: translate(100vw, -50%) rotate(-90deg) scale(0.1);
        opacity: 0.8;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .rocket-fire {
          animation: flash 3s infinite;
          transform: rotate(-180deg);
        }

        img {
          width: 20px;
          &.rocket-fire {
            width: 20px;
          }
        }

        @media (min-width: 991px) {
          img {
            width: 40px;
            &.rocket-fire {
              width: 40px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 991px) {
    height: 900px;
    max-height: 100vh;
    min-height: 600px;
    padding: 0;

    .warp-banner {
      .box-image {
        margin-bottom: 100px;

        img {
          width: 110px;
        }
      }

      h1 {
        font-size: 54px;
        line-height: 68px;
        margin-bottom: 24px;
      }
    }
  }
}

@keyframes upAndDownMargin {
  0% {
    margin-top: -3px;
  }
  45% {
    opacity: 1;
  }
  50% {
    margin-top: 3px;
  }
  60% {
    opacity: 0.2;
  }
  80% {
    opacity: 1;
  }
  100% {
    margin-top: -3px;
  }
}

@keyframes upAndDownTranslate {
  0% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(3px);
  }
}

@keyframes rocketFly {
  0% {
    transform: translate(100vw, -50%) rotate(-90deg) scale(0.1);
  }
  45% {
    transform: translate(0vw, -50%) rotate(-90deg);
  }
  50% {
    transform: translate(0vw, -50%) rotate(90deg);
  }
  95% {
    transform: translate(110vw, -50%) rotate(90deg);
  }
  100% {
    transform: translate(110vw, -50%) rotate(-90deg) scale(1);
  }
}

@keyframes flash {
  0% {
    transform: rotate(-180deg) scale(1);
    opacity: 1;
  }
  45% {
    transform: rotate(-180deg) scale(1.2);
    opacity: 1;
  }
  50% {
    transform: rotate(-180deg) scale(0.7);
    opacity: 0.6;
  }
  55% {
    transform: rotate(-180deg) scale(1.1);
    opacity: 1;
  }
  75% {
    transform: rotate(-180deg) scale(0.5);
    opacity: 0.4;
  }
  100% {
    transform: rotate(-180deg) scale(1);
    opacity: 0.8;
  }
}
