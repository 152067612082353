@import 'assets/css/variables.scss';

.banner-home {
  position: relative;
  // height: 525px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .background-home-1 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    animation: scaleBackground 20s infinite;
  }

  .content {
    padding: 0 15px;
    text-align: center;
    position: relative;
    z-index: 1;

    h1 {
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 110%;
      color: #ffffff;
      width: 100%;
      max-width: 760px;
      margin: auto;
      margin-bottom: 24px;
    }

    p {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 27px;
      color: #e5e6ed;
      width: 100%;
      max-width: 615px;
      margin: auto;
      margin-bottom: 24px;
    }

    button {
      height: 47px;
      align-items: center;
      justify-content: center;
      padding: 18px 27px;
      background: linear-gradient(90deg, #52ffc0 0%, #00c291 100%);
      border-radius: 100px;
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 0;
      color: #ffffff;
      margin-bottom: 0;
      border: none;
      cursor: pointer;
      &:active {
        opacity: 0.9;
      }
    }
  }

  // .typewriter h1 {
  //   color: #fff;

  //   overflow: hidden; /* Ensures the content is not revealed until the animation */
  //   border-right: 0.15em solid orange; /* The typwriter cursor */
  //   // white-space: nowrap; /* Keeps the content on a single line */

  //   animation: typing 3.5s steps(30, end), blink-caret 0.5s  infinite;
  // }

  // /* The typing effect */
  // @keyframes typing {
  //   from {
  //     width: 0;
  //   }
  //   to {
  //     width: 100%;
  //   }
  // }

  // /* The typewriter cursor effect */
  // @keyframes blink-caret {
  //   from,
  //   to {
  //     border-color: transparent;
  //   }
  //   50% {
  //     border-color: orange;
  //   }
  // }

  @media (min-width: 991px) {
    .content {
      h1 {
        font-size: 61px;
        margin-bottom: 32px;
      }

      p {
        font-size: 18px;
        margin-bottom: 64px;
      }

      button {
        height: 65px;
        padding: 18px 75px;
      }
    }
  }
}
