.warp-account-banner {
  width: 100%;
  position: relative;
  overflow: hidden;

  img {
    &.background-banner {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      object-fit: cover;
      pointer-events: none;
      animation: scaleBackground 20s infinite;
    }
  }

  .account-banner {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    padding-bottom: 60px;
    flex-wrap: wrap;
    min-height: 210px;
    max-width: 1300px;
    width: 100%;
    margin: auto;

    .info-user-right {
      display: flex;
      align-items: center;
      margin-bottom: 23px;

      > div {
        &:first-child {
          position: relative;

          img {
            margin-right: 40px;

            &.avatar-edit {
              position: absolute;
              right: 0;
              bottom: 0;
              cursor: pointer;
            }
          }
        }

        &:last-child {
          p {
            &:first-child {
              img {
                cursor: pointer;
              }
            }
          }
        }
      }

      p {
        &:first-child {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 32px;
          color: #fcfcfd;
          margin-bottom: 0;

          img {
            margin-left: 8px;
            width: 17px;
          }
        }

        &:last-child {
          font-family: 'DM Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #fcfcfd;
          margin-bottom: 0;
        }
      }

      @media (min-width: 520px) {
        margin-bottom: 0;
      }

      @media (min-width: 991px) {
        > div {
          &:first-child {
            img {
              margin-right: 30px;
            }
          }
        }

        p {
          &:first-child {
            font-size: 24px;
            margin-bottom: 4px;

            img {
              margin-left: 11px;
              width: 26px;
            }
          }

          &:last-child {
            font-size: 14px;
          }
        }
      }
    }

    .info-user-left {
      display: flex;
      align-items: center;
      align-items: stretch;

      > div {
        &:first-child {
          padding-right: 40px;
          border-right: 1px solid #ffffff;
          display: flex;
          align-items: center;

          p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            color: #ffffff;
            margin-bottom: 0;
          }
        }

        &:last-child {
          padding-left: 40px;

          p {
            &:first-child {
              font-family: 'Graphik';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #ffffff;
              margin-bottom: 8px;

              img {
                margin-right: 16px;
              }
            }

            &:last-child {
              font-family: 'DM Sans';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;
              color: #fcfcfd;
              margin-bottom: 0;
            }
          }
        }
      }

      @media (min-width: 991px) {
        > div {
          &:first-child {
            p {
              font-size: 20px;
              line-height: 140%;
            }
          }

          &:last-child {
            p {
              &:first-child {
                font-size: 16px;
                margin-bottom: 14px;
              }

              &:last-child {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    @media (min-width: 991px) {
      padding-bottom: 40px;
    }
  }
}
