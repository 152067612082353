@import 'assets/css/variables.scss';

article {
  width: 100%;
  min-height: calc(100vh - 466px);
}
/*
* Header  
*/
.ant-layout-header {
  height: 80px;
  padding: 0;
  background: $secondary;

  .menu-container {
    width: 100%;
    height: 100%;
    padding: 0 14px;
    @include media-up(md) {
      padding: 0 50px;
    }
  }
  .content-header {
    width: 100%;
    height: 100%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    > div {
      flex: 1 1 auto;
    }
  }
  .header-left {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;

    .header-logo {
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        max-width: 40px;
        object-fit: contain;
        margin-right: 10px;
      }
      span {
        color: #ffffff;
        font-family: 'Sofia Pro';
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        white-space: nowrap;
      }
    }
    ul.menu-link {
      list-style: none;
      margin: 0;

      display: none;
      flex-flow: row nowrap;
      align-items: center;
      @include media-up(lg) {
        display: flex;
      }

      li {
        white-space: nowrap;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        display: block;
        a {
          color: #5d6588;
          font-family: 'Sofia Pro';
          font-weight: 600;
          font-size: 16px;
          line-height: 160%;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      li.active {
        a {
          color: #ffffff;
        }
      }
    }
  }
  .header-right {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    .header-right-desktop {
      display: none;
      @include media-up(lg) {
        display: flex;
      }
      a {
        display: block;
      }
    }
    .header-right-mobile {
      display: flex;
      align-items: center;
      transform: scale(-1);

      @include media-up(lg) {
        display: none;
      }

      input#menu {
        display: none;
      }

      .icon {
        width: 34px;
        height: auto;
        padding: 0;
        display: block;
        cursor: pointer;
      }

      .icon .menu,
      .icon .menu::before,
      .icon .menu::after {
        background: #fff;
        content: '';
        display: block;
        height: 3px;
        position: absolute;
        transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
      }
      .icon .menu::before {
        width: 28px;
      }
      .icon .menu {
        width: 24px;
      }
      .icon .menu::after {
        width: 34px;
      }

      .icon:hover .menu,
      .icon:hover .menu::before,
      .icon:hover .menu::after {
        background: #fff;
      }

      .icon .menu::before {
        top: -8px;
      }

      .icon .menu::after {
        top: 8px;
      }

      #menu:checked + .icon .menu {
        background: transparent;
      }

      #menu:checked + .icon .menu::before {
        width: 28px;
        transform: rotate(45deg);
      }

      #menu:checked + .icon .menu::after {
        width: 28px;
        transform: rotate(-45deg);
      }

      #menu:checked + .icon .menu::before,
      #menu:checked + .icon .menu::after {
        top: 0;
        transition: top ease 0.3s, transform ease 0.3s 0.3s;
      }
    }
  }
}

/*
* Search
*/
.search-container {
  display: none;
  align-items: center;
  flex-flow: row nowrap;
  margin: 0;
  @include media-up(md) {
    display: flex;
  }
}
.search-container-mobile {
  width: 100%;
  max-width: 300px;
  padding: 0 24px;
  margin-top: 20px;
}

/*
* Ring
*/
.ring-wrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;

  span {
    position: relative;
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 100%;
      z-index: 1;

      position: absolute;
      left: 12px;
      top: 24px;
      background: linear-gradient(90deg, #52ffc0 0%, #00c291 100%);
      pointer-events: none;
    }
    img {
      max-width: 24px;
      object-fit: contain;
    }
  }
}

/*
* Menu content mobile
*/
.menu-mobile-container {
  position: fixed;
  top: 80px;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 0px;
  background: #141518;
  z-index: 10;
  transition: ease-in-out 0.4s;
  overflow: hidden;

  visibility: hidden;
  &[data-visible='true'] {
    visibility: unset;
    height: 100%;
  }

  @include media-up(lg) {
    display: none;
  }
  /*
* Menu mobile Container 
*/
  ul.menu-link {
    list-style: none;
    margin: 16px 0 0;
    flex-flow: column nowrap;

    li {
      white-space: nowrap;
      padding: 16px 30px;
      a {
        color: rgba(93, 101, 136, 1);
        font-family: 'Sofia Pro';
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;

        width: 100%;
        display: block;
        align-items: center;
      }
    }
    li.active {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 49%;
        left: 0;
        transform: translateY(-50%);

        width: 12px;
        height: 12px;
        background: linear-gradient(to right, #15d19d, #141518);
        z-index: 1;
      }
      a {
        color: #ffffff;
      }
    }
  }

  .menu-actions-mobile {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 30px 24px;
    a {
      width: 100%;
      max-width: 127px;
      display: block;
    }
    & > a:first-child {
      margin-right: 16px;
    }
  }
}

.item-chart {
  background: rgba(47, 50, 65, 0.5);
  backdrop-filter: blur(4px);
  padding: 17px 16px;

  .header-chart {
    display: flex;
    justify-content: space-between;

    .box-info {
      display: flex;

      img {
        width: 100%;
        max-width: 44px;
        display: block;
        height: auto;
      }

      > div {
        &:last-child {
          margin-left: 16px;
          flex: 1;

          p {
            font-family: 'Graphik';
            font-style: normal;

            &:first-child {
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #a5adcf;
              margin-bottom: 8px;
            }

            &:last-child {
              font-weight: 600;
              font-size: 20px;
              line-height: 27px;
              color: #ffffff;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    span {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #5d6588;
    }
  }

  .body-chart {
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    > div {
      &:first-child {
        img {
          max-height: 40px;
          max-width: 100%;
        }
      }
    }

    p {
      display: flex;
      align-items: center;
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      margin-bottom: 0;

      &.up {
        color: #11cabe;

        img {
          margin-top: -4px;
        }
      }

      &.down {
        color: #fa2256;
      }
    }
  }

  &.view-all {
    // height: 152px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      display: inline-block;
      padding: 12px 24px;
      background: rgba(47, 50, 65, 0.5);
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 100px;
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
      margin-bottom: 0;
    }

    @media (min-width: 991px) {
      height: 100%;
    }
  }

  @media (min-width: 991px) {
    padding: 25px 24px;

    .header-chart {
      .box-info {
        img {
          max-width: 44px;
        }

        > div {
          &:last-child {
            p {
              &:first-child {
                font-size: 16px;
              }

              &:last-child {
                font-size: 25px;
              }
            }
          }
        }
      }

      span {
        font-size: 16px;
      }
    }

    .body-chart {
      margin-top: 28px;

      p {
        font-size: 18px;
      }
    }
  }
}
