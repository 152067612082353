.warp-account-action {
  position: relative;
  width: 100%;
  padding: 0 15px;
  padding-top: 20px;
  padding-bottom: 20px;

  .account-action {
    max-width: 1350px;
    width: 100%;
    margin: auto;

    .item-action {
      .item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        p {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
        }

        img {
          width: 34px;
          max-height: 20px;
          object-fit: contain;
        }
      }

      .item-description {
        p {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #a5adcf;
        }
      }
    }
  }

  @media (min-width: 991px) {
    padding-top: 25px;
    padding-bottom: 40px;

    .account-action {
      .item-action {
        background: #1e1f25;
        border-radius: 12px;
        padding: 26px 44px;
        height: 100%;

        .item-title {
          margin-bottom: 24px;

          p {
            font-size: 25px;
          }

          img {
            max-width: 40px;
            max-height: 30px;
            object-fit: contain;
          }
        }

        .item-description {
          p {
            font-size: 16px;
            line-height: 24px;
          }
        }

        &:hover {
          background: rgba($color: #1e1f25, $alpha: 0.7);
        }
      }
    }
  }
}
