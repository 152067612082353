.point-item {
  margin-right: 8px;

  &.first-point {
    margin-left: 8px;
  }

  &:last-child {
    margin-right: 0;
  }
}
