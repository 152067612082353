.warp-blog-detail {
  background: #141518;
  overflow-x: hidden;

  p {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #6b7086;
  }

  .container {
    max-width: 1320px;
    width: 100%;
    margin: auto;
    padding: 0 15px;

    .blog-detail-content {
      border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.2);
      margin-bottom: 64px;

      .banner-blog-detail {
        padding: 42px 0 24px 0;
        margin-bottom: 39px;

        img {
          width: 100%;
          border-radius: 54px;
        }
      }

      .header-blog {
        position: relative;
        margin-bottom: 20px;

        .backgound-dot {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(0%, -80%);
          pointer-events: none;
          width: 40%;
        }

        p {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          color: #6b7086;
        }

        h1 {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 130%;
          background: -webkit-linear-gradient(90deg, #52ffc0 0%, #00c291 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          opacity: 0.8;
          margin-bottom: 50px;
        }

        .blog-created-at {
          text-align: right;
          margin-bottom: 0;
          font-family: 'Graphik';
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 0.02em;
          color: #6b7086;
        }
      }

      .body-blog {
        h2 {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;

          letter-spacing: 0.01em;
          color: #ffffff;
          opacity: 0.8;
        }
        p {
          margin-bottom: 24px;
        }

        blockquote {
          background: #1e1f25;
          border-radius: 34px;
          padding: 22px 25px;
          margin: 0 0 24px 0;
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 200%;

          text-align: center;
          letter-spacing: 0.02em;
          color: #ffffff;
          opacity: 0.6;
        }

        @media (min-width: 991px) {
          p {
            margin-bottom: 48px;
          }
          blockquote {
            padding: 65px 85px;
            margin: 0 65px 48px 65px;
          }
        }
      }

      .footer-blog {
        padding-bottom: 16px;

        > p {
          font-family: 'FS Axiforma';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 0.02em;
          color: #ffffff;
          opacity: 0.4;
        }

        .box-share-social {
          .list-social {
            margin-bottom: 16px;

            img {
              margin-right: 24px;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          > p {
            font-family: 'FS Axiforma';
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 0.02em;
            color: #ffffff;
            opacity: 0.4;
          }
        }
      }
    }

    .slider-blog {
      .react-multi-carousel-list {
        margin: 0 -16px;
      }

      .item-blog-slider {
        padding: 0 16px;

        p {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 0.02em;
          color: #f2f2f2;
        }

        img {
          width: 100%;
          pointer-events: none;
        }
      }

      .title-blog-next {
        margin-bottom: 30px;

        p {
          &:first-child {
            font-family: 'Graphik';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 15px;
            color: #f2f2f2;
            opacity: 0.6;
          }

          &:last-child {
            font-family: 'Graphik';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 130%;
            background: linear-gradient(90deg, #52ffc0 0%, #00c291 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
        }
      }
    }
  }

  @media (min-width: 991px) {
    p {
      font-size: 16px;
      line-height: 30px;
    }
    .container {
      .blog-detail-content {
        margin-bottom: 125px;

        .banner-blog-detail {
          padding: 84px 0 60px 0;
          margin-bottom: 82px;
        }

        .header-blog {
          margin-bottom: 50px;

          p {
            font-size: 18px;
            line-height: 20px;
          }

          h1 {
            font-size: 54px;
            line-height: 54px;
            margin-bottom: 0;
          }

          .blog-created-at {
            font-size: 16px;
            line-height: 30px;
          }
        }

        .body-blog {
          h2 {
            font-size: 32px;
            line-height: 35px;
          }

          blockquote {
            font-size: 24px;
            line-height: 46px;
          }
        }

        .footer-blog {
          padding-bottom: 70px;

          > p {
            font-size: 16px;
          }

          .box-share-social {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .list-social {
              margin-bottom: 0;
            }

            > p {
              font-size: 16px;
            }
          }
        }
      }

      .slider-blog {
        padding-bottom: 70px;

        .react-multi-carousel-list {
          margin: 0 -32px;
        }

        .item-blog-slider {
          padding: 0 32px;

          p {
            font-size: 18px;
          }
        }

        .title-blog-next {
          margin-bottom: 62px;

          p {
            &:first-child {
              font-size: 18px;
              line-height: 20px;
            }

            &:last-child {
              font-size: 54px;
              line-height: 68px;
            }
          }
        }
      }
    }
  }
}
