.warp-page-referral-program {
  background-color: #141518;

  .warp-referral-program-content {
    width: 100%;
    padding: 24px;

    .referral-program-content {
      background: #1e1f25;
      border-radius: 12px;
      padding: 20px;
      max-width: 900px;
      margin: auto;

      .go-back {
        margin-bottom: 15px;

        @media (min-width: 991px) {
          margin-bottom: 30px;
        }
      }

      .box-control-referral-program {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 12px;
        padding-bottom: 0;

        .item-control {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          margin-right: 16px;

          > div {
            &:first-child {
              margin-right: 16px;

              img {
                width: 49px;
              }
            }

            &:last-child {
              p {
                font-family: 'Graphik';
                font-style: normal;
                color: #ffffff;

                &:first-child {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 150%;
                  margin-bottom: 7px;
                }

                &:last-child {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 130%;
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        @media (min-width: 991px) {
          padding-top: 25px;
          padding-bottom: 20px;

          .item-control {
            margin-right: 24px;
            margin-bottom: 0;

            > div {
              &:first-child {
                img {
                  width: 52px;
                }
              }

              &:last-child {
                p {
                  &:first-child {
                    font-size: 16px;
                    margin-bottom: 0;
                  }

                  &:last-child {
                    font-size: 20px;
                    line-height: 140%;
                  }
                }
              }
            }
          }
        }
      }

      .referral-gift {
        padding-top: 0;
        padding-bottom: 12px;

        @media (min-width: 991px) {
          padding-top: 25px;
          padding-bottom: 20px;
        }
      }

      .box-share-link {
        padding-top: 12px;
        padding-bottom: 5px;

        .item-share-link {
          padding-top: 24px;
          padding-bottom: 24px;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }

          &:nth-child(1),
          &:nth-child(2) {
            border-bottom: 1px solid #34384c;
          }

          > p {
            font-family: 'Graphik';
            font-style: normal;
            color: #a5adcf;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            margin-bottom: 24px;

            &:first-child {
              font-weight: 600;
              font-size: 14px;
              line-height: 150%;
              color: #ffffff;
              margin-bottom: 8px;
            }
          }

          .box-copy-link {
            max-width: 430px;
          }
        }

        @media (min-width: 991px) {
          padding-top: 20px;
          padding-bottom: 15px;

          .item-share-link {
            padding-top: 36px;
            padding-bottom: 36px;

            > p {
              font-size: 16px;
              line-height: 24px;

              &:first-child {
                font-size: 18px;
                line-height: 27px;
              }
            }

            .box-copy-link {
              max-width: 430px;
            }
          }
        }
      }

      .emble-code {
        padding-top: 10px;
        padding-bottom: 0;

        > p {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #a5adcf;
          margin-bottom: 4px;
        }

        @media (min-width: 991px) {
          padding-top: 15px;

          > p {
            font-size: 16px;
            margin-bottom: 24px;
          }
        }
      }

      @media (min-width: 991px) {
        padding: 66px 90px 99px 90px;
      }
    }
  }
}
